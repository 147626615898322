import React from 'react'
import emailjs from 'emailjs-com';
import BlueMark from '../../assets/Icons/blue check.svg';
import BusinessComponent from '../BusinessComponent/businessComponent';
import Footer from '../../components/Footer/Footer';



const Business = () => {
   const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fs5j8qf', 'template_lobs8gk', e.target, 'hKcBAWhtuJ_plVVJe')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
        e.target.reset();
      alert('Email sent successfully')
     
  };

   /* const handlePhoneValidation = (inputNumber) => {
        let number = /^\d{10}$/;
        if((inputNumber.value.match(number)))  {
      return true;
        }
      else
        {
        alert("message");
        return false;
        }
    }*/
    return (
        <>
        <div className='business-container'>
            <div className='back-color'>
                <div className='employee' >
                <div>
                <h1>EMPLOYEE GINGER IN A PACKAGE</h1>
                <div>
                    <h2 id='enhance'>- Enhancing Productivity</h2>
                    <p className='first-para'>Let's help you increase productivity by giving your employees break from cooking, 
                        laundry and home cleaning. <br />
                        With self butler, your employees enjoy full time off domestic services. 
                    </p>
                </div>
                <button>
                    <a className='btn-a' href=""> Hire a butler today</a>
                </button>
                </div>

                <div className='iphone-container'>
                <img className='iphone-image' src=".\Images\iPhone 12 Pro 6.1_ Mockup.png" alt="" />
                </div>
            </div>
            </div>

            <div className='bonus'>
                <img className='bonus-image' src=".\Images\Team Bonus.png" alt="" />
                <p className='second-para'>A happy team is productive team</p>
            </div>

            <div className='flex-container'>
                <div className='flex-container-1'>
                <div className='flex-card'>
                        <div className='rectangle'>
                            <img className='img-size' src=".\Images\hot-pot 2.png" alt="food" />
                        </div>
                        <div>
                            <h3>Food</h3>
                            <p id='bonus-para'>Let's make a delicious meal delivered to you with a best chef for you and your family</p>
                        </div>
                    </div>

                    <div className='flex-card'>
                        <div className='rectangle'>
                            <img className='img-size' src=".\Images\cleaning 1.png" alt="cleaning" />
                        </div>
                        <div>
                            <h3>Laundry Services</h3>
                            <p id='bonus-para'>High quality laundry on your schedule</p>
                        </div>
                    </div>

                    <div className='flex-card'>
                        <div className='rectangle'>
                            <img className='img-size' src=".\Images\washing-machine 1.png" alt="washing-machine" />
                        </div>
                        <div>
                            <h3>House Cleaning Services</h3>
                            <p id='bonus-para'>We know exactly how a clean space looks like</p>
                        </div>
                    </div>

                    <div className='flex-card'>
                        <div className='rectangle'>
                            <img className='img-size' src=".\Images\giftbox .png" alt="gift-box" />
                        </div>
                        <div>
                            <h3>Gift Boxes</h3>
                            <p id='bonus-para'>Snacks in boxes to reward your employees</p>
                        </div>
                    </div>
                </div>

                    
                <div>
                    <img className='food-img' src=".\Images\food 1.png" alt="food" />
                </div>
            </div>

            <div>
                <h1 id='why-partner'>Why Partner with Butler</h1>
            </div>

            <div className='hero-card'>
                <div className='card-container'>
                    <div>
                        <h4 className='card-text'>Increase Productivity</h4>
                        <img className='card-img' src=".\Images\strong 1.jpg" alt="" />
                        <p className='card-main-text'>Butler helps your employees increase their efficiency,gives them more reason to choose your company and reduce workers workload by giving them more time.</p>
                    </div>

                    <div>
                        <h4 className='card-text'>Real Support</h4>
                        <img className='card-img-1' src=".\Images\helping-hand 1.png" alt="" />
                        <p className='card-main-text-1'>Excellent customer service. That is why every employee is assigned what we call a Butler tech-maid  who is proactive and effective at all times, Proactive and real-time communication so you never miss a detail.</p>
                    </div>

                    <div>
                        <h4 className='card-text'>Unbeatable Price</h4>
                        <img className='card-img-2' src=".\Images\Frame 339.png" alt="" />
                        <p className='card-main-text-2'>Custom offerings and solutions available to help fit your every need. Your team's meal,laundry and cleaning services are on us.</p>
                    </div>
                </div>
            </div>

        <div className='how-work'>
             <div>
            <BusinessComponent
            heading={"How it works"}
            icon={BlueMark}
            text_1={"Fill the form on this page"}
            text_2={"Enter your discount code"}
            text_3={"You get a Butler plan for your team"}
            text_4={"We'll set up your employee's account"}
            text_5={"Every team member gets personalized service"}
            text_6={"Team satisfaction"}
            />
            <button>
                    <a className='btn-a' href=""> Hire a butler today</a>
            </button>
            </div>

            <div>
                <img className='iphone-work' src=".\Images\Frame 340.jpg" alt="" />
            </div>
        </div>
        
           
            <div className='hire-container'>
                <div className='hire-heading'>
                    <h4 className='hire-text'>Hire a Butler today for employee benefits that boost <span className='prod'>Productivity</span></h4>
                    <p className='hire-para'>Hi! We need the following informatin to enable us reach out to you about then next steps. Let's get started!</p>
                </div>

                <div className='hire-form'>
                    <form action="" onSubmit={sendEmail}>
                        <div className='hire-detail'>
                            <label className='form-label' htmlFor="fullName">Contact's Name</label>
                            <input className='form-input' type="text" id='fullName' name='fullName' placeholder='Enter Name'  required/>
                        </div>

                        <div className='hire-detail'>
                            <label className='form-label' htmlFor="email">Contact's Email Address</label>
                            <input className='form-input' type="text"  id='email' name='email' placeholder='Enter email address' required />
                        </div>

                        <div className='hire-detail'>
                            <label className='form-label' htmlFor="job">Contact's Job Role</label>
                            <input className='form-input' type="text" id='job' name='job' placeholder='Enter job role' required/>
                        </div>

                        <div className='hire-detail'>
                            <label className='form-label' htmlFor="number">Contact's Phone Number</label>
                            <input className='form-input' type="tel"  id='number' name='number' placeholder='Enter phone number' required/>
                        </div>


                        <div className='hire-detail'>
                            <label className='form-label' htmlFor="message">Anything else you'll like us to know</label>
                            <input className='form-input-1' type="text"  id='message' name='message' placeholder='Type here'  style={{ paddingTop: '15px' }} required/>
                        </div>
                        
                        <button className='last-btn' type='submit'>
                            <a className='btn-a' href=""> Hire a butler </a>
                        </button> <br />
                        <a className='last-anchor' href="#">Go Back</a>
                    </form>
                </div>
            </div> 
        </div> 
        <Footer/>
        </> 
    );
}


export default Business