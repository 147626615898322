import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export const ServiceContextData = createContext(null);
const BASE_URL = 'https://butler-api-v1.herokuapp.com/api';

export const ServiceContext = (props) => {
  const [servicesState, setServicesState] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/v1/services`)
      .then((response) => {
        const { data } = response.data;
        // console.log(response.data);
        setServicesState(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setServicesState]);

  return (
    <ServiceContextData.Provider value={servicesState}>
      {props.children}
    </ServiceContextData.Provider>
  );
};
