import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ErrorSvg } from '../../assets/Icons/error.svg';
const NoMatch = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  return (
    <div className="NoMatch">
      <ErrorSvg />
      <div class="message-box">
        <h1>404</h1>
        <p>Page not found</p>
        <div class="buttons-con">
          <div class="action-link-wrap">
            <button onClick={goBack} class="link-button link-back-button">
              Go Back
            </button>
            <Link to="/" class="link-button">
              Go to Home Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
