import { Link } from 'react-router-dom';

const HeroBtnDesktop = ({ url, text='Get started' }) => {
  return (
    <>
      {url ? (
        <button className="ctadesktop">
          <Link to={url} className="ctadesktop-link">
            {text}
          </Link>
        </button>
      ) : (
        <button className="ctadesktop">
          <a href="https://app.usebutler.africa/" className="ctadesktop-link">
            Start making ur life easier
          </a>
        </button>
      )}
    </>
  );
};

export default HeroBtnDesktop;
