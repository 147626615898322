import React from 'react'
export default function Service({heading, summary, icon, text_1, text_2, text_3, img}) {
  return (
    <div className="services__box">
      <div className="services__container">
        <div className="services__description">
          <h2 className="services__heading">{heading}</h2>
          <h4 className="services__summary">{summary}</h4>
          <div className="list-items">
            <div className="items">
              <img src={icon} alt="checkmark" />
              <p className="list">{text_1}</p>
            </div>
            <div className="items">
              <img src={icon} alt="checkmark" />
              <p className="list">{text_2}</p>
            </div>
            <div className="items">
              <img src={icon} alt="checkmark" />
              <p className="list">{text_3}</p>
            </div>
          </div>
        </div>
        <div className="services__image-box">
          <img className="services__image" src={img} alt="clothe" />
        </div>
      </div>
    </div>
  )
}