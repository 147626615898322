import uuid from 'react-uuid';
import axios from 'axios';

export const tabs = (isPageWide) => {
  return [
    {
      id: 1,
      name: 'Wash & Fold',
      label: 'Wash & fold',
      img: 'https://res.cloudinary.com/butlerdesigns/image/upload/c_scale,h_336,w_560/v1631657582/Assets/folded2_rreryx.jpg',
      header: 'Everyday laundry, washed and folded. No ironing',
      list: [
        'Create an account (30% welcome discount)',
        'Select your items',
        'Get your items washed, ironed & delivered',
      ],
    },
    {
      id: 2,
      name: 'Wash & Iron',
      label: 'Wash & iron',
      img: 'https://res.cloudinary.com/butlerdesigns/image/upload/c_scale,w_560/v1631657689/Assets/folded_hhosmb.jpg',

      header:
        'Get your laundry washed and ironed, with an option of being placed on hangers.',
      list: [
        'Create an account (30% welcome discount)',
        'Select your items',
        'Get your items washed, ironed & delivered',
      ],
    },
    {
      id: 3,
      name: 'Dry Cleaning',
      label: 'Dry cleaning',
      img: 'https://res.cloudinary.com/butlerdesigns/image/upload/c_scale,w_560/v1631657684/Assets/hanged_qjfdsx.jpg',
      header: 'Get your delicate items and fabrics drycleaned.',
      list: [
        'Create an account (30% welcome discount)',
        'Select your items',
        'Get your items dry cleaned',
      ],
    },

    {
      id: 4,
      name: 'Iron Only',
      label: 'Iron only',
      img: 'https://res.cloudinary.com/butlerdesigns/image/upload/c_scale,h_373,w_560/v1631657712/Assets/iron_lfffr9.jpg',
      header: 'You take care of the washing while we iron perfectly for you.',
      list: [
        'Create an account (30% welcome discount)',
        'Select your items',
        'Get your items ironed',
      ],
    },

    {
      id: 5,
      name: 'Duvets & bulky items',
      label: isPageWide ? `Duvets & bulky items` : `Bulky items`,
      img: 'https://res.cloudinary.com/butlerdesigns/image/upload/c_scale,w_560/v1631657663/Assets/duvet_ex9l0y.jpg',
      header: 'Wash larger items that require special care',
      list: [
        'Create an account (30% welcome discount)',
        'Select your items',
        'Get your bulky items taken care of',
      ],
    },
  ];
};

export const clothCategory = [
  {
    desc: 'Tops',
    parts: [
      {
        name: 'Cardigan',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Sweater',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Heavy sweater',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Top/blouse',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'T-shirt on hanger',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'T-shirt on hanger',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Polo shirt folded',
        price: 100,
        id: uuid(),
        qty: 0,
      },
    ],
  },
  {
    desc: 'Bottoms',
    parts: [
      {
        name: 'Jeans',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Shorts',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Skirts',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Trousers',
        price: 100,
        id: uuid(),
        qty: 0,
      },
    ],
  },

  {
    desc: 'Dresses',
    parts: [
      {
        name: 'Jeans',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Evening gowns',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Jumpsuit',
        price: 100,
        id: uuid(),
        qty: 0,
      },
    ],
  },
  {
    desc: 'Acccessories & homeware',
    parts: [
      {
        name: 'Cardigan',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Scarf',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Beddings',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Pillowcases',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Bath mat',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Aprons',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Napkins',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Tablecloth',
        price: 100,
        id: uuid(),
        qty: 0,
      },
    ],
  },

  {
    desc: ' Outerwear',
    parts: [
      {
        name: 'Kaftan',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Jacket/blazer',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Vest',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Winter coat',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Outer jacket',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Agbada',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Danshiki',
        price: 100,
        id: uuid(),
        qty: 0,
      },
      {
        name: 'Suits',
        price: 100,
        id: uuid(),
        qty: 0,
      },
    ],
  },
];

export const duvetCategory = {
  desc: ' Outerwear',
  parts: [
    {
      name: 'Single blankets',
      price: 100,
      id: uuid(),
      qty: 0,
    },
    {
      name: 'Double blankets',
      price: 100,
      id: uuid(),
      qty: 0,
    },
    {
      name: 'Big towels',
      price: 100,
      id: uuid(),
      qty: 0,
    },
    {
      name: 'Small towels',
      price: 100,
      id: uuid(),
      qty: 0,
    },
    {
      name: 'Curtains',
      price: 100,
      id: uuid(),
      qty: 0,
    },
  ],
};

const BASE_URL = 'https://butler-api-v1.herokuapp.com/api';

export const getServices = async () => {
  const res = await axios.get(`${BASE_URL}/v1/services`);
  const { data } = await res.data;
  // console.log(data);
  return data;
};

export const getCategories = async () => {
  const res = await axios.get(`${BASE_URL}/v1/categories`);
  const { data } = await res.data;
  return data;
};

