import React from 'react'

export default function FoodComponent({heading,img, summary, icon, text_1, text_2, text_3}) {
    return (
        <div className="business__box">
            <div className="business__container">
                <div className="business__description">
                    <h2 className="business__heading">{heading}</h2>
                    <h5 className="services__summary">{summary}</h5>
                    <div className="list-items">
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_1}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_2}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_3}</p>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}
  