import HeroBtn from '../../components/BtnHero/BtnHero';
import HeroBtnDesktop from '../../components/BtnHero/BtnHeroDesktop';


function Hero() {
  return (
    <div className={`hero__section`}>
      <div className="hero__section-container">
        <div className="hero-container-desc">
          <h1 className='hero-container-desc__heading'>
            <span className='hero-container-desc__heading-p'>
              Make your life easier
            </span> 
            <span className='hero-container-desc__heading-s'>
              than before without
            </span>
            <span className='hero-container-desc__heading-anim'></span>
            
          </h1>
          <ul className="hero-container-desc__list">
            <li className="hero-container-desc__item">
              Never run out of clean clothes. We pickup (dirty clothes) and deliver (clean clothes).
            </li>
            <li className="hero-container-desc__item">
              We know exactly how a clean space should look like
            </li>
            <li className="hero-container-desc__item">
              Meals delivered on schedule <span className='soon'>(Coming soon)</span>
            </li>
          </ul>
          <HeroBtnDesktop/>
        </div>

        <div className="hero-container-illustration">
          <div className="hero-image-container">
            <img
              src="https://res.cloudinary.com/butlerdesigns/image/upload/v1631657675/Assets/hero_mtaznt.png"
              alt=""
            />
          </div>
        </div>
        <HeroBtn/>
      </div>
    </div>
  );
}

export default Hero;
