import React from 'react';
import Arrow from '../../assets/Icons/arrow.svg';
function Step({ icon, hasArrow, title, text }) {
  return (
    <div className={`step__container`}>
      <div className={`icons__area`}>
        <img src={icon} alt="" width="80px" />
        <div className="step__content">
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      </div>

      {hasArrow && <img src={Arrow} alt="" width="100px" className={`arrow`} />}
    </div>
  );
}

export default Step;
