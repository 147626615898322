import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FaBars, FaAngleUp, FaTimes, FaAngleDown } from 'react-icons/fa';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setDropDown(false);
    setClick(false);
  };

  return (
    <div className="header">
      <div className="logo-nav">
        <div className="logo-container">
          <Link className="logo" to="/">
            Butler
          </Link>
        </div>
      </div>
      <ul className={click ? "nav-options active" : "nav-options"}>
        <li className="option">
          <div
            onClick={() => setDropDown(!dropDown)}
            className="links service-link"
          >
           <div className = "service-flexx" > Services {dropDown ? <FaAngleUp /> : <FaAngleDown />} </div>
            <div className={`dropdown ${dropDown && "show-dropdown"}`}>
              <div className="dropdown-item">
                <a onClick={closeMobileMenu} href="https://app.usebutler.africa">
                  Laundry
                </a>
              </div>
              <div className="dropdown-item">
                {/* <NavLink onClick={closeMobileMenu} to="/house-cleaning"> */}
                <a onClick={closeMobileMenu} href="https://app.usebutler.africa">
                  Home Cleaning
                </a>
              </div>
              <div className="dropdown-item">
                <a onClick={closeMobileMenu} href="https://app.usebutler.africa">
                  Food (Coming soon...)
                </a>
              </div>
            </div>
          </div>
        </li>
        <li className="option" onClick={closeMobileMenu}>
          <NavLink className="link" to="/about">
            About Us
          </NavLink>
        </li>
        <li className="option" onClick={closeMobileMenu}>
          <NavLink className="link" to="/contact">
            Contact
          </NavLink>
        </li>
        <li className="option" onClick={closeMobileMenu}>
          <NavLink className="link" to="/price">
            Pricing
          </NavLink>
        </li>
        <li className="option" onClick={closeMobileMenu}>
          <NavLink className="link" to="/business">
            Business
          </NavLink>
        </li>
        <li className="button" onClick={closeMobileMenu}>
          <a
            className="login"
            href={"https://app.usebutler.africa"}
          >
            Login
          </a>
        </li>
        <li className="button" onClick={closeMobileMenu}>
          <a
            className="signup"
            href={"https://app.usebutler.africa"}
          >
            Sign up
          </a>
        </li>
      </ul>

      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <FaTimes className="menu-icon" />
        ) : (
          <FaBars className="menu-icon" />
        )}
      </div>
    </div>
  );
};

export default Navbar;
