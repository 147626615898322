import Hero from '../../sections/Hero';
//import Services from '../../sections/Services';
import Service from '../../sections/Service';
import TrustedSection from '../../sections/TrustedSection';
import HowButlerWorks from '../../sections/HowButlerWorks';
import Footer from '../../components/Footer/Footer';
function Home() {
  // const context = useContext(ServiceContextData);
  // const [services, setServices] = useState(null);
  // // if the context is null then the data have not been fetched yet
  // if (!context) {
  //   console.log('fetch'); // maybe a loading indicator
  // } else {
  //   setServices(context);
  // }
  return (
    <div className={`landing__page`}>
      <Hero />
      <Service />
      {/*<Services />*/}
      {/* <LaundrySection /> */}
      <TrustedSection />
      <HowButlerWorks />
      <Footer />
    </div>
  );
}

export default Home;
