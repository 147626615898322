import React from "react"
import { useState } from "react";
import BlueMark from '../../assets/Icons/blue check.svg';
import { ButtonGroup } from "@material-ui/core";
import FoodComponent from "../FoodComponent/foodComponent";
import Footer from "../Footer/Footer";

const FoodCard = () => {
  let src = ".\Images\new-food.png";

  const [value, setValue] = useState(1);

    return (
      <>
      <div id="main-content">
        <div className="main-container">
          <div className="food__box">
      <FoodComponent
        heading={"Food"}
        summary={
          "Let's make a delicious meal delivered to you with the best chef for you and your family on schedule"
        }
        icon={BlueMark}
        text_1={"Choose from 100+ meals"}
        text_2={"We'll deliver to you on schedule"}
        text_3={"You will enjoy your freshly cooked meals on schedule"}
        />
          <img className="new-food" src=".\Images\new-food.png" alt="Food" /> 
          </div>
        </div>
      </div>

        <div className="food_container" id="container-1">
          <div className="first_items">
            <h3>How often should your food be delivered?</h3>
            <div className="btn-space">
              
              <button className="btn-desc">Daily</button>
              <button className="btn-desc">Once a week</button>
              <button className="btn-desc">Twice a week</button>
              
            </div>
          </div>

            <div className="second_items">
            <h3>Meals per delivery</h3>
            <div  className="btn-value">
              <button className="btn-minus" onClick={()=> setValue(value - 1)}>-</button>
              <h4>{value}</h4>
              <button className="btn-plus" onClick={()=> setValue(value + 1)}>+</button>
            </div>
          </div>

          <div className="third_items">
            <h3>Preferred days for delivery(choose 5 or more days)</h3>
            <div className="btn-space">
              <button className="btn-desc">Monday</button>
              <button className="btn-desc">Tuesday</button>
              <button className="btn-desc">Wednesday</button>
              <button className="btn-desc">Thursday</button>
              <button className="btn-desc">Friday</button>
              <button className="btn-desc">Saturday</button>
              <button className="btn-desc">Sunday</button>
            </div>
          </div>

          <div className="fourth_items">
            <h3>Preferred time of delivery</h3>
            <div className="btn-space">
              <button className="btn-desc">8-9 A.M</button>
              <button className="btn-desc">10-11 A.M</button>
              <button className="btn-desc">12-1 P.M</button>
              <button className="btn-desc">2-4 P.M</button>
              <button className="btn-desc">5-6 P.M</button>
            </div>
          </div>
          </div>  
<Footer/>
</>
    );
};




export default FoodCard;