import { Link } from 'react-router-dom';

const HeroBtn = ({ url, text='Get started' }) => {
  return (
    <>
      {url ? (
        <button className="ctaM">
          <Link to={url} className="ctaM-link">
            {text}
          </Link>
        </button>
      ) : (
        <button className="ctaM">
          <a href="https://app.usebutler.africa/" className="ctaM-link">
            Start making ur life easier
          </a>
        </button>
      )}
    </>
  );
};

export default HeroBtn;
