import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import 'react-dropdown/style.css';
import CounterComponent from '../../components/Counter/Counter';
import Footer from '../../components/Footer/Footer';
import TypeContainer from '../../components/TypesContainer/TypesContainer';
import CommonButton from '../../components/Button/Button';

const Pricing = () => {
  // const [unique, setUnique] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [list, showList] = useState(false);

  const laundryBtns = [
    'Wash & Fold',
    'Wash & Iron',
    'Dry cleaning',
    'Iron only',
  ];

  const laundryTypes = ['Every two weeks', 'Once a month', 'Once a week'];

  return (
    <>
      <div className="pricing">
        <div className="pricing-service-types">
          <span className="types-header">Types of Services</span>

          <div className="pricing-plans">
            <button className="plan-btn">
              <Link to="/pricing" className="plan-btn-link">
                One off
              </Link>
            </button>
            <button className="plan-btn">
              <a
                href="https://9jgu37qty2m.typeform.com/to/PmlJcPlK"
                className="plan-btn-link"
              >
                Business Plan
              </a>
            </button>
            <button className="plan-btn">Subscription</button>
          </div>
        </div>

        <div className="pricing-customization">
          <span className="section-header">Customise your plans</span>
            <div className="laundry-plan plans">
              <p className="section-header">Laundry Plan</p>
              <TypeContainer desc="Type" buttonContent={laundryBtns} />
              <div className="laundry-frequency">
                <TypeContainer desc="Frequency" buttonContent={laundryTypes} />
              </div>

              <div className="laundry-num-bag">
                <CounterComponent />
                <span className="mini-desc">30 items per bag</span>
              </div>

              <div className="laundry-items">
                <div
                  className="laundry-items-header"
                  onClick={() => showList((prev) => !prev)}
                >
                  <p>Items that will cost you more</p>
                  {!list ? <FaAngleDown /> : <FaAngleUp />}
                </div>
                <div className={!list ? "item-test" : "item-test active"}>
                  <div className="parts">
                    <span className="part-item">Big size towel (2 items)</span>
                    <span>500</span>
                  </div>
                  <div className="parts">
                    <span className="part-item">Bedsheet (3 items)</span>
                    <span>900</span>
                  </div>
                  <div className="parts">
                    <span className="part-item">Blanket (3 items)</span>
                    <span>1500</span>
                  </div>
                  <div className="parts">
                    <span className="part-item">Agbada (5 items)</span>
                    <span>4000</span>
                  </div>
                  <div className="parts">
                    <span className="part-item">Curtain (1 items)</span>
                    <span>900</span>
                  </div>
                  <div className="parts">
                    <span className="part-item">Duvet (2 items)</span>
                    <span>1800</span>
                  </div>
                  <div className="parts">
                    <span className="part-item">Suit (3 items)</span>
                    <span>2400</span>
                  </div>
                </div>
              </div>

              <div className="section-amt">
                <p>Laundry price</p>
                <span>N***</span>
              </div>
            </div>

          <div className="section-amt price">
            <p>Total price</p>
            <span>N***</span>
          </div>
          <div className="section-cta">
            <CommonButton />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;