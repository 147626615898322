import { Link } from 'react-router-dom';

const CommonButton = ({ url, text='Get Started' }) => {
  return (
    <>
      {url ? (
        <button className="cta">
          <Link to={url} className="cta-link">
            {text}
          </Link>
        </button>
      ) : (
        <button className="cta">
          <a href="https://app.usebutler.africa/" className="cta-link">
            Get started
          </a>
        </button>
      )}
    </>
  );
};

export default CommonButton;
