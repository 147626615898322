import React from 'react';
// import './trusted.style.scss';
// import ChevronLeft from "../../assets/Icons/chevron-left.svg";
function TrustedCard({ title, text, icon }) {
  return (
    <div  className={`trusted__cards--container`}>
      {/* <h1 className="title">{title}</h1>
       */}
      <img src={icon} alt="icons" />
      <p className="text-content">{text}</p>
    </div>
  );
}

export default TrustedCard;
