import {useState, useEffect } from "react";

import "react-dropdown/style.css";
import Footer from "../../components/Footer/Footer";
import LaundryCards from  '../../components/Card/LaundryCards'
import HouseCleaning from "../../components/HomeCleaning/HouseCleaning";

const Pricing_Cards = () => {
  const [openLaundry, setOpenLaundry] = useState(true);
  const [openHomeCleaning, setOpenHomeCleaning] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="pricing_cards">
        <div className="title">
          <h1 className="pricing_title">Want a subscription plan?</h1>
        </div>
        {openLaundry && (
          <div className="laundry-container">
            <span className="title_desc">Check out our prices below </span>
            <span className="desc__opts">*wash & iron</span>
            <LaundryCards />

            <div className="alternate">
              <h1 className="alternate_title">
                Are you interested in a custom-tailored plan?
              </h1>
              <span className="alternate_desc">
                Calculate prices to your specific needs
              </span>
              
                <button className="alternate_button" type="submit">
                    <a className="btn-price" href="https://app.usebutler.africa/signup">Get started</a>
                </button>
              
            </div>
          </div>
        )}
        {openHomeCleaning && (
          <div className="home-cleaning">
            <span className="desc__opts">
              Check out our monthly prices below
            </span>
            <div className={openHomeCleaning ? "top-cleaning" : "cleaning"}>
              <HouseCleaning />
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Pricing_Cards;
