const LaundryCards = () => {
  return (
    <div className="pricing__cards">
      <div className="plain__card">
        <h3 className="card__tite">Corer Plan</h3>
        <h1 className="card__price">14,200</h1>
        <span className="card__price-desc">Naira</span>
        <ul className="card__details">
          <li className="card__details-item">20 items</li>
          <li className="card__details-item">2 Towels</li>
          <li className="card__details-item">2 Duvets</li>
          <li className="card__details-item">2 Bedsheets</li>
        </ul>

        <button className="card__button">

          <span>
            <a className="new-btn" href="http://paystack.com/pay/aox3hijk88">Get Started</a>
            </span>
          

        </button>
      </div>

      <div className="coloured__card">
        <h3 className="card__tite">Riven Plan</h3>
        <h1 className="card__price">19,200</h1>
        <span className="card__price-desc">Naira</span>
        <ul className="card__details">
          <li className="card__details-item">14 items</li>
          <li className="card__details-item">5 Suits</li>
          <li className="card__details-item">2 Towels</li>
          <li className="card__details-item">1 Duvets</li>
          <li className="card__details-item">2 Bedsheets</li>
        </ul>
        <button className="card__button">
          <span>
            <a className="new-btn-1" href="http://paystack.com/pay/ngzi4y164d">Get Started</a>
            </span>
          
        </button>
      </div>

      <div className="plain__card">
        <h3 className="card__tite">Brade Plan</h3>
        <h1 className="card__price">43,600</h1>
        <span className="card__price-desc">Naira</span>
        <ul className="card__details">
          <li className="card__details-item">10 Suits</li>
          <li className="card__details-item">5 Duvets</li>
          <li className="card__details-item">5 Bedsheets</li>
          <li className="card__details-item">4 Towels</li>
          <li className="card__details-item">5 Kaftan</li>
          <li className="card__details-item">5 Agbada</li>
        </ul>
        <button className="card__buttonn">
          <span>
            <a className="new-btn" href="http://paystack.com/pay/62ykb344du">Get Started</a>
            </span>
        </button>
      </div>
    </div>
  );
};

export default LaundryCards;
