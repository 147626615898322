import React from 'react'

export default function BusinessComponent({heading, icon, text_1, text_2, text_3, text_4, text_5, text_6}) {
    return (
        <div className="business__box">
            <div className="business__container">
                <div className="business__description">
                    <h2 className="business__heading">{heading}</h2>
                    <div className="list-items">
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_1}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_2}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_3}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_4}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_5}</p>
                        </div>
                        <div className="items">
                            <img src={icon} alt="checkmark" />
                            <p className="list">{text_6}</p>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}
  