import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import useMediaQuery from '../../hooks/useMediaQuery';
import Footer from '../../components/Footer/Footer';
import CommonButton from '../../components/Button/Button';
import EmojiList from '../../components/EmojiList/EmojiList';
import { clothCategory, duvetCategory, getServices, tabs, getCategories } from '../../data';
import { CategoryContextData } from '../../contexts/categoryContext';
import { ServiceContextData } from '../../contexts/servicesContext';


const Laundry = () => {
  let history = useHistory();
  let data = useContext(CategoryContextData);
  let serviceData = useContext(ServiceContextData);
  const thisService = serviceData?.find(x=> x.name === "Laundry")?._id;
  const allLaundryCat = data?.filter(x=> x.service = thisService);
  const getMinMax = (category)=> {
    let r = {
      min_sales_item_price: 0,
      max_sales_item_price: 0
    }
    allLaundryCat.forEach(cat=> {
      if (cat.name === category) {
        const minPrice = Math.min(...cat.sales_item.map(x=> x.price))
        const maxPrice = Math.max(...cat.sales_item.map(x=> x.price))
        // console.log(cat.sales_item.map(x=> x.price), minPrice)
        // console.log(cat.sales_item.map(x=> x.price), maxPrice)
        if (r.min_sales_item_price === 0 || minPrice < r.min_sales_item_price) {
          r.min_sales_item_price = minPrice
        }
        if (r.max_sales_item_price === 0 || maxPrice > r.max_sales_item_price) {
          r.max_sales_item_price = maxPrice
        }
      }
    })
    return r
  }


  // console.log(min_sales_item_price)
  // console.log(max_sales_item_price)
  const [show, setShow] = useState(false);
  let isPageWide = useMediaQuery('(min-width: 768px)');

  const handleShowCalculator = () => {
    setShow((prev) => !prev);
  };

  const tabList = tabs(isPageWide);
  console.log('tablist is ', tabList)
  const [cloth, setCloth] = useState(clothCategory);

  const setQty = (id, quantity) => {
    const [tops, ...anything] = cloth;
    const topsPart = tops.parts.map((item) =>
      item.id === id
        ? {
            ...item,
            qty: quantity,
          }
        : item
    );
    setCloth([{ ...tops, parts: topsPart }, ...anything]);
  };

  // const sum = () =>
  //   cloth[0].parts.reduce((acc, item) => acc + item.qty * item.price, 0);

  const [currentTab, setCurrentTab] = useState(tabList[0].id);
  const [currentTabName, setCurrentTabName] = useState(tabList[0].name);
  const [items, setItems] = useState(0);
  const [service, showService] = useState(false);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  const handleRoute = () => history.push('/pricing');

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="laundry">
        <div>
          <div className="tabs">
            {tabList.map((tab, i) => (
              <button
                key={i}
                onClick={() => { setCurrentTab(tab.id); setCurrentTabName(tab.name) }}
                className={tab.id === currentTab ? 'active' : ''}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {tabList?.map(
            (tab, i) =>
              tab &&
              tab?.id === currentTab && (
                <div className="tab-container" key={i}>
                  <div className="tab-content">
                    <h2>{tab?.header}</h2>
                    <EmojiList list={tab.list} />
                    {isPageWide && (
                      <div className="tab-link">
                        <p className="show-calc" onClick={handleShowCalculator}>
                          Want an estimate? Try out our laundry calculator
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="content-img">
                    {/* <h1>{getMinMax(tab.name).max_sales_item_price }</h1>
                    <h1>{getMinMax(tab.name).min_sales_item_price }</h1> */}
                    <div className="content-img-container">
                      <img src={tab?.img} alt="hero" />
                    </div>
                    {!isPageWide && (
                      <div className="tab-link">
                        <p className="show-calc" onClick={handleShowCalculator}>
                          Want an estimate? Try out our laundry calculator
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )
          )}
        </div>

        <form
          onSubmit={handleSubmit}
          className={`pricing ${show && 'show-pricing'}`}
        >
          <div className="items-input">
            <h4>Number of items</h4>
            <div className="number-input">
              <button
                onClick={() => setItems((prev) => prev - 1)}
                className="number-toggler"
              >
                -
              </button>
              <input
                className="quantity"
                min={Number(0)}
                name="quantity"
                value={items && Math.max(0, items)}
                onKeyPress={preventMinus}
                onChange={(e) =>
                  setItems(
                    e.target.value ? Number(e.target.value) : e.target.value
                  )
                }
                type="number"
              />
              <button
                onClick={() => setItems((prev) => prev + 1)}
                className="number-toggler"
              >
                +
              </button>
            </div>
          </div>

          <div className="service-type">
            <h4>Type of service</h4>
            <div className="service-buttons">
              <button className="service-button active">One off</button>
              {/* <button className="service-button">Business plan</button> */}
              <button onClick={handleRoute} className="service-button sub">
                Subscription
              </button>
            </div>
          </div>
          <div
            onClick={() => showService((prev) => !prev)}
            className={!service ? 'see-more' : 'see-more clear'}
          >
            Want to see More Advanced options. Click here
          </div>

          {service && (
            <>
              <div className="service-units">
                <h4>
                  {currentTab === 1
                    ? 'Items to be washed & folded'
                    : currentTab === 2
                    ? 'Items to be washed & ironed'
                    : currentTab === 3
                    ? 'Items to be dry cleaned'
                    : currentTab === 4
                    ? 'Items to be ironed'
                    : currentTab === 5
                    ? 'Bulky items to be washed'
                    : ''}
                </h4>

                {currentTab !== 5 ? (
                  <div className="cloth-parts">
                    {cloth.map((item, idx) => (
                      <div
                        className={item && item.desc2 && 'column-display'}
                        key={idx}
                      >
                        <h4>{item.desc}</h4>
                        {item.parts.map((part, j) => (
                          <Counter
                            desc={part.name}
                            amt={part.price}
                            quantity={part.qty}
                            setQuantity={setQty}
                            id={part.id}
                            key={part.id}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="cloth-parts">
                    <div>
                      <h4>{duvetCategory.desc}</h4>
                      {duvetCategory &&
                        duvetCategory.parts.map((part, j) => (
                          <Counter
                            desc={part.name}
                            amt={part.price}
                            quantity={part.qty}
                            setQuantity={setQty}
                            id={part.id}
                            key={part.id}
                          />
                        ))}
                    </div>
                  </div>
                )}
                {/* 
                {currentTab === 5 && (
                  <div className="cloth-parts">
                    <div>
                      <h4>{duvetCategory.desc}</h4>
                      {duvetCategory &&
                        duvetCategory.parts.map((part, j) => (
                          <Counter
                            desc={part.name}
                            amt={part.price}
                            quantity={part.qty}
                            setQuantity={setQty}
                            id={part.id}
                            key={part.id}
                          />
                        ))}
                    </div>
                  </div>
                )} */}
              </div>

            </>
          )}


          <div className="price-section">
                <div className="showcase">
                  <span className="price">Price</span>
                  {/* <span className="amt">N{currentTabName}</span> */}
                  <span className="amt">N{ getMinMax(currentTabName).min_sales_item_price*items} - {getMinMax(currentTabName).max_sales_item_price*items}</span>
                </div>
              </div>

              <div className="get-started">
                {/* <button className="get-started-btn">Get Started</button> */}
                <CommonButton />
              </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

const currencyFormatter = (amount) => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(amount);
};

const Counter = ({ desc, amt, quantity, id, setQuantity }) => {
  return (
    <>
      <p className="desc">
        {desc} {currencyFormatter(amt)}
      </p>
      <div className="counter-container">
        <button
          className="control"
          onClick={() => quantity > 0 && setQuantity(id, quantity - 1)}
        >
          -
        </button>
        <p className="item-num">{quantity}</p>
        <button
          className="control"
          onClick={() => setQuantity(id, quantity + 1)}
        >
          +
        </button>
      </div>
    </>
  );
};

export default Laundry;
