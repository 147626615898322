import { useState } from 'react';

const CounterComponent = ({ desc }) => {
  const [items, setItems] = useState(0);
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <>
      <span className="counter-header">{desc ? desc : 'Number of bags'}</span>
      <div className="counter-counter">
        <button onClick={() => setItems((prev) => prev - 1)}>-</button>
        <input
          min={Number(0)}
          name="quantity"
          value={items && Math.max(0, items)}
          onKeyPress={preventMinus}
          onChange={(e) =>
            setItems(e.target.value ? Number(e.target.value) : e.target.value)
          }
          type="number"
        />
        <button onClick={() => setItems((prev) => prev + 1)}>+</button>
      </div>
    </>
  );
};

export default CounterComponent;
