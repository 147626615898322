import './App.scss';
import { ServiceContext } from './contexts/servicesContext';
import { CategoryContext } from './contexts/categoryContext';
import Pages from './routes';
function App() {
  return (
    <ServiceContext>
    <CategoryContext>
      <div className="app">
        <Pages />
      </div>
    </CategoryContext>
    </ServiceContext>
  );
}

export default App;
