import React from 'react';
// import './trusted.style.scss';
import Card from './Cards';
import customer1 from '../../assets/Icons/cust 1.png';
import customer2 from '../../assets/Icons/cust 2.png';
import customer3 from '../../assets/Icons/cust 3.png';
import TrustedButton from '../../components/Button/TrustedBtn'
function TrustedSection() {
  return (
    <div style={{background: '#DCE6FE'}} className={`trusted__section--container`}>
      <div className="trusted__section--header">
        <h1>Delivering fresh laundry in 48 hours or less</h1>
        {/* <p>
          Hundreds of families across Abuja trust us to manage their tasks and
          house chores
        </p> */}
        <p>We built it with everyone in mind</p>
        <ul>
        <li>Subscription ✅</li>
        <li>One-off ✅</li>
        </ul>

      </div>

      <div className="cards__section">
        <Card
          title="We use vetted service providers"
          text="Forget laundry day without running out of clean clothes"
          icon={customer1}
        />
        <Card
          title="We use vetted service providers"
          text="You are in control. Modify, pause or cancel your Butler subscription anytime"
          icon={customer2}
        />
        <Card
          title="We use vetted service providers"
          text="Clothing care as an art. Choose your perfect plan"
          icon={customer3}
        />
      </div>

      <TrustedButton/>
    </div>
  );
}

export default TrustedSection;
