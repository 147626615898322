import React, {useEffect} from "react";
import Footer from "../../components/Footer/Footer";
import HouseCleaning from "../../components/HomeCleaning/HouseCleaning";




const HomeCleaning = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <>
     <HouseCleaning/> 
      <Footer />
    </>
  );
};;

export default HomeCleaning
