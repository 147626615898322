import Step from './Step';
import CreateIcon from '../../assets/Icons/create-account-icon.svg';
import CartIcon from '../../assets/Icons/subsrcibe-to-service-icon.svg';
import GetServiceIcon from '../../assets/Icons/get-service-icon.svg';
import CommonButton from '../../components/Button/Button';

function HowButlerWorks() {
  return (
    <div>

<div id="how-butler-works" className={`how__butler__work--container`}>
      <div className="how__butler__work--header">
        <h1>
          How <span>Butler</span> Work
        </h1>
        <span className="how__butler__work--desc">
          Start automating your tasks and house chores with our 3-simple step
          process
        </span>
      </div>

      <div className="steps">
        <Step
          icon={CreateIcon}
          hasArrow
          title="Create an Account"
          text="Create an account on Butler"
        />
        <Step
          icon={CartIcon}
          hasArrow
          title="Subscribe to a service"
          text="Subscribe to one or more of our services"
        />
        <Step
          icon={GetServiceIcon}
          title="Get your service"
          text="Get your chores done with our amazing services"
        />
      </div>
      <div className={`get__started--btn desktop`}>
        <CommonButton />
      </div>
    </div>
    
    <div style={{background: '#FFF8E7'}} id="last-section-works" className={`how__butler__work--container`}>
      <div className={`try__butler`}>
        <div className="try__butler--left">
          <div className="main__heading">
            <span className="try-butler">
              Are you ready to be <span>taken care of?</span>
            </span>
            <h3>Try Butler today</h3>
          </div>

          <p className="content">
            We built everything with you in mind. Just get expert cleaning,
            laundry and meals from the comfort of your phone and rest.{' '}
            <span className="reliable">Reliabilty</span> is our word!
          </p>
          <div className="right">
          <img
            src="https://res.cloudinary.com/butlerdesigns/image/upload/v1631657583/Assets/cup_volmda.png"
            alt="try butler"
          />
        </div>
          <div className="common-btn">
            <CommonButton />
          </div>
        </div>
       
      </div>
    </div>
    
    </div>

  );
}

export default HowButlerWorks;
