import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AboutHeroImage from '../../assets/Icons/about-bg.png';
import CommonButton from '../../components/Button/Button';
import Footer from '../../components/Footer/Footer';

const About = () => {
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about">
        <div className="about-hero">
          <img src={AboutHeroImage} alt="" className="about-hero__img" />
        </div>
        <div className="about-container">
          <div className="about-story">
            <h4 className="about-heading">Our Story</h4>
            <p>
              <span className="begin">In the beginning... </span>
              people didn’t have to bother about clothes, dirt or work. Remember
              Adam and Eve...
            </p>
            <p className='mobile-view'>
              In reality we have to ensure good appearance, productivity at
              work, earn a living and still deal with home chores and laundry!
              Life can be easier somehow, can’t it? Maybe nobody can do your job
              for you but why not your chores and laundry? Having been raised to
              manage the family’s laundry business, our founder, Solomon
              Aiyepeku has not only learnt the business of looking good and
              clean, but he has also drunk off the value-stream of customer
              relations and is driven to solve problems through innovation.
              These values and more are instilled into everything we do at
              Butler with every customer and every service.
            </p>
            <p className='mobile-view'>
              Butler is a simple, high-quality solution to take care of your
              closet and handle your general house cleanings. We’re excited to
              formally launch as the most convenient cleaning and laundry
              benefit available for YOU here in Abuja. Your tech-maid is in the
              neighbourhood!
            </p>
            <p>Is this you;</p>
            <div className="about-spans">
              <span>
                - Constantly worried about getting your laundry to and from the
                dry cleaners?
              </span>
              <span>
                - Need to rest or be more productive but are stuck with laundry,
                cleaning and insects?
              </span>
              <span>
                - Just got a new apartment or finished your building project?
              </span>
            </div>

            <p>
              then you definitely need BUTLER.{' '}
              <span className="aim">
                Our aim is to help you get the best out of life!
              </span>
            </p>
          </div>

          <div className="about-mission">
            <h4>
              Delivering happy, safe and clean homes across Africa is what we
              want to be known for. Starting with Nigeria’s capital city, Abuja.
            </h4>

            <p className='mobile-view'>
              We seek to make it easy for the working to get the best out of
              life by solving many of your day-to-day cleaning problems leaving
              you to focus on your main business. Butler is also available for
              teams who want the best for and from their members. Stay off
              laundry but never run out of clean clothes. Your home no matter
              the size, spic and span! Insects and rodents don’t exist in your
              world. Now you can make time out for the things that matter most
              to you. What’s better?
            </p>
            <p>
              You can get all of our services with a subscription button! Life
              can be easier!.
            </p>
          </div>

          <div className="about-doings">
            <h4 className="about-main__header">What we do</h4>
            <span className="doing-span">
              HOW DO YOU FEEL WHEN THINGS ‘JUST WORK’ WITH BARELY ANY EFFORT
              FROM YOU? - We put all efforts and deliver result in these areas:
            </span>
            <div className="about-services">
              {/* <ul> */}
              <li>Laundry and Dry Cleaning</li>
              {/* </ul> */}

              <span>NEVER RUN OUT OF FRESH CLOTHES.TIME.ENERGY</span>

              <p className='mobile-view'>
                We'll pick your laundry, hygienically clean it, and deliver it
                back to you cleaned, ironed and nicely packaged - ready-to-wear.
              </p>

              <p className='mobile-view'>
                With Butler, you enjoy dry cleaning and laundry, ironing and
                stain removal services. We handle every material/fabric
                delicately and follow their special care instructions.
              </p>

              <p className='mobile-view'>
                7 days a week, we are available to pick-up and deliver your
                laundry and dry cleaning in 48hours or less at an unbeatable
                price in Abuja. *As a bonus also offer light repairs, like
                fabric tears and replacing zippers.
              </p>

              <p>
                We take pride in our wealth of experience and high level of
                service in dry cleaning and steam pressing.{' '}
                <span className="cta-text"> <a href="https://app.usebutler.africa/signup"> Sign up here!</a></span>

              </p>
            </div>

            <div className="about-services">
              <li> Home Cleaning:</li>
              <span className="sub-header">LEAVE THE DIRTY WORK TO US</span>
              <p className='mobile-view'>
                Butler turns every space into a happy place with our residential
                cleaning services and post-construction cleaning service. Get a
                professional cleaning touch on your apartment, family homes, new

                building projects. <span className="cta-text"><a href="https://app.usebutler.africa/signup">Start here!</a></span>

              </p>
            </div>

            <div className="about-services">
              <li> Food:</li>
              <span className></span>
              <p className='mobile-view'>
                Forget market runs, ignore the spike in cost of ingredients and raw foods and let's worry about that for you. We do all the work for you. Just pay once a month and get food everyday.
              </p>

              <p className='mobile-view'>
                We serve a rich blend of unique customer experience, guaranteed
                quality and user-friendly  technology to make things ‘just work’
                for you. Keep enjoying these services using a{' '}

                <span className="cta-text"><a href="https://app.usebutler.africa/signup">One-time subscription offer.</a></span>

              </p>
            </div>
            <div>
              <h4>WHY WE DO IT</h4>

              <p>TO GIVE YOU THE BEST OUT OF LIFE</p>

              <p>These are the things that matter to us:</p>

              <div className="about-services">
                <li>Unique Customer Experience:</li>
                <p>
                  Our ultimate goal is YOU; heightened productivity and
                  satisfaction. Your personal preferences are saved and
                  remembered. We’re experts in clothing care and work with the
                  best cleaning partners. Our team will go all out to ensure
                  you’re served and pleased.
                </p>
              </div>

              <div className="about-services">
                <li>Time & Efficiency:</li>
                <p>
                  Your time is of essence and in fact, money - our intention is
                  to optimize it. Your pickups, delivery and cleaning are
                  attended to promptly on schedule.
                </p>
              </div>

              <div className="about-services">
                <li>Trust:</li>
                <p>
                  Allowing us to handle your clothes and into your home is a
                  trust we cannot betray. We keep open communication and provide
                  access to your account on the website where you can track your
                  laundry delivery. Items found in your clothes would be safely
                  kept and returned. You’re in safe hands.
                </p>
              </div>

              <div className="about-services">
                <li>Eco-Friendly:</li>
                <p>
                  We keep both your clothes and the planet sustainable. We
                  recycle hangers, avoid toxic chemicals for the environment and
                  accept clothing donations for charity/recycling.
                </p>
              </div>

              <p>
                We believe our commitment to providing top-notch service at
                competitive prices is the primary reason for our success.
>>>>>>> homepage-new
              </p>
            </div>
          </div>
        </div>

        <div className="about-cta">
          <CommonButton/>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
