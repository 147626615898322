import { Link } from 'react-router-dom';

const TrustedButton = ({ url, text='Get Started' }) => {
  return (
    <>
      {url ? (
        <button className="ctaTrusted">
          <Link to={url} className="ctaTrusted-link">
            {text}
          </Link>
        </button>
      ) : (
        <button className="ctaTrusted">
          <a href="https://app.usebutler.africa/" className="ctaTrusted-link">
            Get started
          </a>
        </button>
      )}
    </>
  );
};

export default TrustedButton;
