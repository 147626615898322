const TypeContainer = ({ desc, buttonContent, onClick, active }) => (
  <>
    {desc && <span className="types-header">{desc}</span>}

    <div className="types-types">
      {buttonContent &&
        buttonContent.map((item, idx) => (
          <button
            key={idx}
            className={`${active === item ? "active" : ""}`}
            onClick={onClick}>
            {item}
          </button>
        ))}
    </div>
  </>
);

export default TypeContainer;
