import Emoji from '../../assets/Icons/emoji1.png';
import Emoji1 from '../../assets/Icons/emoji2.png';
import Emoji2 from '../../assets/Icons/emoji3.png';

const EmojiList = ({ list }) => {
  return (
    <div className="emoji-items">
      {list.map((point, idx) => (
        <div key={idx} className="items">
          <div className="img">
            {idx === 0 ? (
              <img src={Emoji} height="15" width="15" alt="emojis" />
            ) : idx === 1 ? (
              <img src={Emoji1} height="15" width="15" alt="emojis" />
            ) : (
              <img src={Emoji2} height="15" width="15" alt="emojis" />
            )}
          </div>
          <p className="list"> {point}</p>
        </div>
      ))}
    </div>
  );
};

export default EmojiList;
