import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FaBars, FaAngleUp, FaTimes, FaAngleDown } from 'react-icons/fa'
import Footer from '../Footer/Footer';

const NewPricePage = () => {
     const [click, setClick] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setDropDown(false);
    setClick(false);
  };
    return (
        <div>
        <div className='new-container'>
        <div className='new-pricing-container'>
            <div>
                <h2 id='heading'>What service are you interested in?</h2>
                <p id='first-para'>Choose any service you are interested in and Butlers expert will take care of it for you</p>
            </div>

            <div className='pricing-display'>
                <ul>
                    <li id='list'>
                        <Link to = '/pricing'>Laundry</Link>
                        <img src="" alt="" />
                    </li>
                    
                    <li id='list'>
                        <Link to = '/house-cleaning'>Home Cleaning</Link>
                        <img src="" alt="" />
                    </li>
                    <li id='list'>
                        <Link to = '#'>Food (Coming Soon)</Link>
                        <img src="" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div> <br /> <br />
    <Footer/>
    </div>
    )
}



export default NewPricePage;