import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Twitter from '../../assets/Icons/fa-brands_twitter-square.svg';
import Instagram from '../../assets/Icons/akar-icons_instagram-fill.svg';
import Facebook from '../../assets/Icons/brandico_facebook-rect.svg';
import Email from '../../assets/Icons/clarity_email-solid.svg';
import WhyButler from '../WhyButler/whyButler';
function Footer() {
  return (
    <React.Fragment>
      <div className={`footer`}>
        <div className={`footer-left`}>
          <div className="heading">
            <h1>Butler</h1>
            <p>Making your life so much easier</p>
          </div>
          <div className="office">
            <h5>Abuja Office:</h5>
            <p>
              No.3 Rabat Street, Off Herbert Macaulay Way, Abuja, FCT, Nigeria
            </p>
            <p className="footer_telephone">
              TEL: <a href="tel:+2348168830764">+234 816 883 0764</a>
            </p>
          </div>
        </div>
        <div className="footer-right">
          <ul>
            <li>Company</li>
            <li>
              <Link to="/about">About us</Link>
            </li>
            <li>
              <Link to="/">Career</Link>
            </li>{" "}
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>{" "}
            <li>
             <Link to="/whyButler"> Why Butler</Link>
            </li>{" "}
          </ul>
          <ul>
            <li>Services</li>
            <li>
              <a href="https://app.usebutler.africa">Laundry</a>
            </li>{" "}
            <li>
              <a href="https://app.usebutler.africa">Home cleaning</a>
            </li>{" "}
            <li>
              <a href="https://app.usebutler.africa">Food</a>
            </li>
            {/* <li>
              <Link to="/gas-delivery">Gas Delivery</Link>
            </li>
            <li>
              <Link to="/cooking">Cooking</Link>
            </li> */}
          </ul>
          <ul className='others'>
            <li>Others</li>
            <li>
              <HashLink
                to="/#how-butler-works"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "auto", block: "start" })
                }
              >
                How Butler Works
              </HashLink>
            </li>{" "}
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>{" "}
    
          </ul>
          <div className="officeMobile">
              <h5>Abuja Office:</h5>
              <p>
                No.3 Rabat Street, Off Herbert Macaulay Way, Abuja, FCT, Nigeria
              </p>
              <p className="footer_telephone">
                TEL: <a href="tel:+2348168830764">+234 816 883 0764</a>
              </p>
            </div>
        </div>
      </div>
      <div className={`copyright`}>
        <div className={`year`}>
          {new Date().getFullYear()} Butler. All Rights Reserved
        </div>
        <div className={`icons`}>
          <a
            href="https://instagram.com/butlerafrica"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="" />
          </a>
          <a
            href="https://facebook.com/butlerafrica"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="" />
          </a>

          <a
            href="https://twitter.com/butlerafrica"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} alt="" />
          </a>

          <a href="mailto:info@usebutler.africa" target="_blank" rel="noreferrer">
            <img src={Email} alt="" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
