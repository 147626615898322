import React from "react";
import Home from "./Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Login from './Login/Login';
// import Signup from './Signup/Signup';
import Navbar from "../components/NavBar/Navbar";
import Laundry from "./Laundry/Laundry";
import HomeCleaning from "./HomeCleaning/HomeCleaning";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Pricing_Cards from "./Pricing/Pricing-Cards";
import Pricing from "./Pricing/Pricing";
import Fumigation from "./Fumigation/Fumigation";
import NoMatch from "./404/NoMatch";
import WhyButler from "../components/WhyButler/whyButler";
import FoodCard from "../components/Food/food";
import NewPricePage from "../components/NewPrice/price";
import Business from "../components/Business/business";

function Pages() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/about" component={About} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/laundry" component={Laundry} exact />
        <Route path="/house-cleaning" component={HomeCleaning} exact />
        <Route path="/pricing" component={Pricing_Cards} exact />
        <Route path="/advanced-pricing" component={Pricing} exact />
        <Route path="/whyButler" component={WhyButler} exact />
        <Route path="/food" component={FoodCard} exact />
        <Route path="/price" component={NewPricePage} exact />
        <Route path="/business" component={Business} exact />
        <Route path="/" component={Home} exact />
        <Route path="*" component={NoMatch} exact />
      </Switch>
    </Router>
  );
}

export default Pages;
