import React from "react";


const WhyButler = () => {
    return (
        <div className="about-services">
              <h4 className="">WHY WE DO IT</h4>

              <p className="give">TO GIVE YOU THE BEST OUT OF LIFE</p>

              <p>These are the things that matter to us:</p>

              <div className="about-services">
                <li>Unique Customer Experience:</li>
                <p>
                  Our ultimate goal is YOU; heightened productivity and
                  satisfaction. Your personal preferences are saved and
                  remembered. We’re experts in clothing care and work with the
                  best cleaning partners. Our team will go all out to ensure
                  you’re served and pleased.
                </p>
              </div>

              <div className="about-services">
                <li>Time & Efficiency:</li>
                <p>
                  Your time is of essence and in fact, money - our intention is
                  to optimize it. Your pickups, delivery and cleaning are
                  attended to promptly on schedule.
                </p>
              </div>

              <div className="about-services">
                <li>Trust:</li>
                <p>
                  Allowing us to handle your clothes and into your home is a
                  trust we cannot betray. We keep open communication and provide
                  access to your account on the website where you can track your
                  laundry delivery. Items found in your clothes would be safely
                  kept and returned. You’re in safe hands.
                </p>
              </div>

              <div className="about-services">
                <li>Eco-Friendly:</li>
                <p>
                  We keep both your clothes and the planet sustainable. We
                  recycle hangers, avoid toxic chemicals for the environment and
                  accept clothing donations for charity/recycling.
                </p>
              </div>

              <p>
                We believe our commitment to providing top-notch service at
                competitive prices is the primary reason for our success.
              </p>
            </div>
    )
}



export default WhyButler;