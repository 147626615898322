import React from 'react'
import { useEffect } from 'react';
import emailjs from 'emailjs-com';
import {
  FaEnvelope,
  FaMobile,
  FaInstagram,
  FaFacebookSquare,
  FaTwitterSquare,
  FaMapPin,
} from 'react-icons/fa';
import Footer from '../../components/Footer/Footer';




const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fs5j8qf', 'template_lobs8gk', e.target, 'hKcBAWhtuJ_plVVJe')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
        e.target.reset();
      alert('Email sent successfully')
     
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="contact">
        <div className="contact-details">
          <div className="contact-details__info">
            <span className="details__main">Contact information</span>
            <span className="details__desc">
              Fill up the form and our team will get back to you in 24 hours
            </span>
          </div>

          <div className="contact-details__links">
            <div className="links">
              <a href="mailto: info@usebutler.africa" className="link">
                <FaEnvelope /> <span>info@usebutler.africa</span>
              </a>

              <a href="https://instagram.com/butlerafrica" className="link">
                <FaInstagram /> <span>@butlerafrica</span>
              </a>

              <a href="https://facebook.com/butlerafrica" className="link">
                <FaFacebookSquare /> <span>@butlerafrica</span>
              </a>

              <a href="https://twitter.com/butlerafrica" className="link">
                <FaTwitterSquare /> <span>@butlerafrica</span>
              </a>

              <a className="link" href="tel:+2348139422386">
                <FaMobile /> <span>{`+234 816 883 0764`}</span> 
              </a>

              <div className="address">
                <FaMapPin />{' '}
                <span>
                  No.3 Rabat Street, Off Herbert Macaulay Way, Abuja, FCT,
                  Nigeria
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <div className="contact-form-heading">
            <span>We’d love to hear from you</span>
            <span>Any questions or remarks? Just send us a message</span>
          </div>

          <form onSubmit={sendEmail} className="form">
            <div className="form-input-container">
              <input
                className="form-input"
                type="text"
                placeholder="Full Name"
                name="fullName"
              />
            </div>

            <div className="form-input-container">
              <input
                className="form-input"
                name="email"
                type="email"
                placeholder="Email Address"
              />
            </div>

            <div className="form-input-container">
              <textarea
                className="textarea"
                placeholder="Type your message here"
                id=""
                cols="30"
                rows="10"
                name="message"
              ></textarea>
            </div>

            <div className="form-input-container">
              
              <button className="submit-button">
                <a href='' className="new-link">Send message</a>
              </button>
              
            </div>
          </form>
        </div>
      </div>
      <div className="contact-footer">
        <Footer />
      </div>
    </>
  );
};

export default Contact;
