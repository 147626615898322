import React, { useState, useEffect } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import { GoPlusSmall } from "react-icons/go";
import { BiMinus, BiChevronDown } from "react-icons/bi";

const HouseCleaning = () => {

  const [price, setPrice] = useState(0)
  const [list, showList] = useState(false);
  const [showLightCleaning, setShowLightCleaning] = useState(true);
  const [showDeepCleaning, setShowDeepCleaning] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [bedrooms, setBedrooms] = useState('-- Please select the number of rooms--')
    
  // const [frequencyActive, setFrequencyActive] = useState(false);
  const [lightFrequency, setLightFrequency] = useState("");
  const [deepFrequency, setDeepFrequency] = useState("");

 {/*
 const [livingCount, setLivingCount] = useState(1);
  const [bedroomCount, setBedroomCount] = useState(1);
  const [toiletCount, setToiletCount] = useState(1);
  const [kitchenCount, setKitchenCount] = useState(1);
  const [studyCount, setStudyCount] = useState(1);
  const [outdoorCount, setOutdoorCount] = useState(1);
*/}


  //const calculatePrice = (newprice) => {
  // let total = price + newprice;
  //  setPrice(total)
  // }

  const frequencyPrices = {
    oneOff: {
      '1 bedroom & 1 toilet': 3500,
      '2 bedroom & 2 toilet': 4000,
      '3 bedroom & 3 toilet': 7000,
      '4 bedroom & 4 toilet': 9000,
      '5 bedroom & 5 toilet': 10000,
      '6 bedroom & 6 toilet': 11000
    },
    twiceAWeek: {
      '1 bedroom & 1 toilet': 6700,
      '2 bedroom & 2 toilet': 7700,
      '3 bedroom & 3 toilet': 13600,
      '4 bedroom & 4 toilet': 17500,
      '5 bedroom & 5 toilet': 18600,
      '6 bedroom & 6 toilet': 20000
    },
    weekly: {
      '1 bedroom & 1 toilet': 12000,
      '2 bedroom & 2 toilet': 14000,
      '3 bedroom & 3 toilet': 24000,
      '4 bedroom & 4 toilet': 32000,
      '5 bedroom & 5 toilet': 35000,
      '6 bedroom & 6 toilet': 38000
    }
  }

  const frequencyItems = [
    {
      id: "oneOff",
      title: "One off",
    },
    {
      id: "twiceAWeek",
      title: "twice a week",
    },
    {
      id: "weekly",
      title: "weekly",
    },
  ];

  const deepFrequencyPrices = {
    deepOneOff: {
      '1 bedroom & 1 toilet': 30000,
      '2 bedroom & 2 toilet': 45000,
      '3 bedroom & 3 toilet': 60000,
      '4 bedroom & 4 toilet': 75000,
      '5 bedroom & 5 toilet': 90000,
      '6 bedroom & 6 toilet': 105000
    }
  }

  const deepFrequencyItems = [{
    id: "deepOneOff",
    title: "One off",
  }];

  function handleBedroomChange(e) {
  setBedrooms(e.target.value)
  console.log(bedrooms)
}

const handleChangeLightFrequency = (item) => {
    setLightFrequency(item.title)
    // setPrice(item.price)
    const frequency = frequencyPrices[item.id]
    const totalPrice = frequency[bedrooms]
    setPrice(totalPrice)
  }

  const handleChangeDeepFrequency = (item) => {
    setDeepFrequency(item.title)
    //setPrice(item.price)
    const deepFrequency = deepFrequencyPrices[item.id]
    const deepTotalPrice = deepFrequency[bedrooms]
    setPrice(deepTotalPrice)
  }


  {/*const handleMinus = (e) => {
    e.preventDefault();
    setBedroomCount(bedroomCount - 1);

    if (bedroomCount < 2) {
      setBedroomCount(1);
    }
  };*/}

  const handleShowCategories = () => {
    setShowLightCleaning(!showLightCleaning);
    setShowDeepCleaning(!showDeepCleaning);
  };
  // const handleFrequencyBtn = () => {
  //   setFrequencyActive(!frequencyActive);
  // };


  return (
    <div className="home-cleaning">
      <div className="home-container">
        <div className="home-select-titles">
          <span className={showLightCleaning ? "active" : "title"}
            onClick={() => handleShowCategories()}>
            Standard cleaning
          </span>
          <span className={showDeepCleaning ? "active" : "title"}
            onClick={() => handleShowCategories()}>
            Post Construction cleaning
          </span>
        </div>

        {showLightCleaning && (
          <div className="light-cleaning">
            <div className="cleaning-accordion">
              <div className="cleaning-accordion-header">
                {/*onClick={() => showList((prev) => !prev)} */}
                <p>What is Standard cleaning?</p>
                {/*{!list ? <FaAngleDown /> : <FaAngleUp />} */}
              </div>
              <div className={!list ? "item-test" : "item-test active"}>
                <div className="parts">
                  <span className="part-item">
                    Sweeping and mopping of all floors
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">Dust and wipe all surfaces</span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Cobweb removal from all surfaces
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">Wash dirty dishes</span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Arrange furniture, lay bed & fold clothes
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Polish all wood & glass surfaces
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Scrub & wash urinals, toilets and toilet seats
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">lean base & behind toilet</span>
                </div>
                <div className="parts">
                  <span className="part-item">Empty & clean trash can</span>
                </div>
                <div className="parts">
                  <span className="part-item">Light switches cleaned</span>
                </div>
              </div>
            </div>

            <div className="drop-down-container">
              <span className="title">Number of rooms to be cleaned?</span>
              <div
                className="drop-down"
                onClick={() => setShowOverlay((prev) => !prev)}>
                  <span>
                    {bedrooms}
                  </span>
               {/*  <span>
                  {bedroomCount} bedrooms , {livingCount} Living rooms,{" "}
                  {toiletCount} toilets, {kitchenCount}kitchens
                </span>*/}
                <BiChevronDown className="down-icon" />
              </div>

              {showOverlay && (
                <div className="overlay">
                  <div className="overlay-item">
                   <select defaultValue={bedrooms}  onChange={handleBedroomChange} className="overlay-item-title">
                        <option value="">  -- Select-- </option>
                        <option value="1 bedroom & 1 toilet" className="overlay-item-title"> 1 Bedroom & 1 Toilet</option>
                        <option value="2 bedroom & 2 toilet"> 2 Bedroom & 2 Toilet</option>
                        <option value="3 bedroom & 3 toilet"> 3 Bedroom & 3 Toilet</option>
                        <option value="4 bedroom & 4 toilet"> 4 Bedroom & 4 Toilet</option>
                        <option value="5 bedroom & 5 toilet"> 5 Bedroom & 5 Toilet</option>
                        <option value="6 bedroom & 6 toilet"> 6 Bedroom & 6 Toilet</option>
                    </select>
                    {/*<select className="overlay-item-title" id="bedroom&toilet" name="bedroom&toilet"> 
                    
              </select> */}
                   {/*  <div className="overlay-item-btns">
                      <BiMinus className="overlay-btn" onClick={handleMinus} />
                      <span className="counter">{bedroomCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setBedroomCount(bedroomCount + 1)}
                      />
                      </div>*/}
                    
                  </div>
                  
                    {/* 
                    <div className="overlay-item">
                    <span className="overlay-item-title">
                      Living/ Dining rooms
                    </span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setLivingCount(livingCount - 1)}
                      />
                      <span className="counter">{livingCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => {
                          setLivingCount(livingCount + 1)
                          const total = price + (livingCount + 1 * 700)
                          setPrice(total)
                        }}
                      />
                    </div>
                    </div>*/}
                  
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">
                      Toilets/ Bathrooms
                    </span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setToiletCount(toiletCount - 1)}
                      />
                      <span className="counter">{toiletCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setToiletCount(toiletCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">Kitchens</span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setKitchenCount(kitchenCount - 1)}
                      />
                      <span className="counter">{kitchenCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setKitchenCount(kitchenCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">Study/ Store</span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setStudyCount(studyCount - 1)}
                      />
                      <span className="counter">{studyCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setStudyCount(studyCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">Outdoor/ Balcony</span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setOutdoorCount(outdoorCount - 1)}
                      />
                      <span className="counter">{outdoorCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setOutdoorCount(outdoorCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  <button
                    className="close-overlay-btn"
                    onClick={() => setShowOverlay(!showOverlay)}>
                    Done
                  </button>
                </div>
              )}
              <div className="frequency">
                <span className="frequency-title">Frequency</span>
                <div className="frequency-btns">
                  {frequencyItems.map((item) => {
                    return (
                      <button
                        className={`frequency-btn ${
                          lightFrequency === item.title ? "active" : "  "
                          }`}
                        key={item.id}
                        onClick={() => handleChangeLightFrequency(item)}>
                        {item.title}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className="price">
                <span>Price</span>
                <span className="total-price">{price}</span>
              </div>
              <button className="get-started-btn">
                <a className="cleaning" href="https://app.usebutler.africa/signup">Get started</a>
              </button>
            </div>
          </div>
        )}
        {showDeepCleaning && (
          <div className="deep-cleaning">
            <div className="cleaning-accordion">
              <div
                className="cleaning-accordion-header">
                {/*onClick={() => showList((prev) => !prev)} */}
                <p>What is Post Construction cleaning?</p>
                {/*{!list ? <FaAngleDown /> : <FaAngleUp />} */}
              </div>
              <div className={!list ? "item-test" : "item-test active"}>
                <div className="parts">
                  <span className="part-item">
                    Sweeping and mopping of all floors
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">Dust and wipe all surfaces</span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Cobweb removal from all surfaces
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">Wash dirty dishes</span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Arrange furniture, lay bed & fold clothes
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Window glasses shined and cleaned
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Inside window seals brushed out
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Doorknobs and doorframes polished
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">Empty & clean trash can</span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Polish all wood & glass surfaces
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Disinfect and wash toilets & urinals
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">Empty and clean trash can</span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Scrape floors, walls & clean all surfacess
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Clean internal & external parts of home appliances (Gas
                    cookers, refrigirators etc)
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Clean cupboards and cabinets AC vents cleaned
                  </span>
                </div>
                <div className="parts">
                  <span className="part-item">
                    Cleaning of standing & ceiling fans Passage & walkways swept
                    and cleaned
                  </span>
                </div>
              </div>
            </div>

            <div className="drop-down-container">
              <span className="title">Number of rooms to be cleaned?</span>
              <div
                className="drop-down"
                onClick={() => setShowOverlay((prev) => !prev)}>
                  <span>{bedrooms}</span>
                {/* <span>
                  {bedroomCount} bedrooms , {livingCount} Living rooms,{" "}
                  {toiletCount} toilets, {kitchenCount}kitchens
                </span>*/}
                <BiChevronDown className="down-icon" />
              </div>

              {showOverlay && (
                <div className="overlay">
                  <div className="overlay-item">
                    <select defaultValue={bedrooms} onChange={handleBedroomChange} className="overlay-item-title">
                        <option value="">  -- Select-- </option>
                        <option value="1 bedroom & 1 toilet" className="overlay-item-title"> 1 Bedroom & 1 Toilet</option>
                        <option value="2 bedroom & 2 toilet"> 2 Bedroom & 2 Toilet</option>
                        <option value="3 bedroom & 3 toilet"> 3 Bedroom & 3 Toilet</option>
                        <option value="4 bedroom & 4 toilet"> 4 Bedroom & 4 Toilet</option>
                        <option value="5 bedroom & 5 toilet"> 5 Bedroom & 5 Toilet</option>
                        <option value="6 bedroom & 6 toilet"> 6 Bedroom & 6 Toilet</option>
                    </select>
                    {/*
                    <span className="overlay-item-title"> Bedrooms</span>
                    <div className="overlay-item-btns">
                      <BiMinus className="overlay-btn" onClick={handleMinus} />
                      <span className="counter">{bedroomCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setBedroomCount(bedroomCount + 1)}
                      />
                    </div>*/}
                  </div>
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">
                      Living/ Dining rooms
                    </span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setLivingCount(livingCount - 1)}
                      />
                      <span className="counter">{livingCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setLivingCount(livingCount + 1)}
                      />
                    </div>
                     </div>*/}
                 
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">
                      Toilets/ Bathrooms
                    </span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setToiletCount(toiletCount - 1)}
                      />
                      <span className="counter">{toiletCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setToiletCount(toiletCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">Kitchens</span><div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setKitchenCount(kitchenCount - 1)}
                      />
                      <span className="counter">{kitchenCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setKitchenCount(kitchenCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  
                   {/*
                   <div className="overlay-item">
                    <span className="overlay-item-title">Study/ Store</span>
                    <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setStudyCount(studyCount - 1)}
                      />
                      <span className="counter">{studyCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setStudyCount(studyCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  
                    {/*
                    <div className="overlay-item">
                    <span className="overlay-item-title">Outdoor/ Balcony</span>
                     <div className="overlay-item-btns">
                      <BiMinus
                        className="overlay-btn"
                        onClick={() => setOutdoorCount(outdoorCount - 1)}
                      />
                      <span className="counter">{outdoorCount}</span>
                      <GoPlusSmall
                        className="overlay-btn"
                        onClick={() => setOutdoorCount(outdoorCount + 1)}
                      />
                    </div>
                    </div>*/}
                  
                  <button
                    className="close-overlay-btn"
                    onClick={() => setShowOverlay(!showOverlay)}>
                    Done
                  </button>
                </div>
              )}
              <div className="frequency">
                <span className="frequency-title">Frequency</span>
                <div className="frequency-btns">
                  {deepFrequencyItems.map((item) => {
                    return (
                      <button
                        className={`frequency-btn ${
                          deepFrequency === item.title ? "active" : "  "
                          }`}
                        key={item.id}
                        onClick={() => handleChangeDeepFrequency(item)}>
                        {item.title}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className="price">
                <span>Price</span>
                <span>{price}</span>
              </div>
              <button className="get-started-btn">
                <a className="cleaning" href="https://app.usebutler.africa/signup">Get started</a>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HouseCleaning;
