import { Link } from 'react-router-dom';

const OrangeBtn = ({ url, text='Coming Soon' }) => {
  return (
    <>
      {url ? (
        <button className="ctaO">
          <Link to={url} className="ctaO-link">
            {text}
          </Link>
        </button>
      ) : (
        <button className="ctaO">
          <a href="#" className="ctaO-link">
            Coming Soon
          </a>
        </button>
      )}
    </>
  );
};

export default OrangeBtn;
