import React from 'react'
import ServiceComponent from '../../components/Service/Service'
import GreenMark from '../../assets/Icons/green check.svg';
import OrangeMark from '../../assets/Icons/orange check.svg';
import BlueMark from '../../assets/Icons/blue check.svg';
import ClotheFord from '../../assets/Icons/clothe laundary.png';
import Clothe from '../../assets/Icons/fold laundary.png';
import Food from '../../assets/Icons/food.svg';
import CommonButton from '../../components/Button/Button'
import OrangeBtn from '../../components/Button/OrangeButton'

export default function Service() {

  return (
    <div className="services">
      <div className="services__title-box">
        <h2 className="services__title">Our services</h2>
      </div>
      
      <ServiceComponent
        heading={"Laundry Services"}
        summary={"High quality laundry on your schedule"}
        icon={GreenMark}
        text_1={"We get your clothes picked up"}
        text_2={"Have our professional expertly take care of your clothes"}
        text_3={
          "Clothes are returned to you in 48 hours crisp, clean and ready-to-wear"
        }
        img={ClotheFord}/>
       <div className='newBtn'>
        <CommonButton/>
        </div>

      <ServiceComponent
        heading={"House Cleaning Services"}
        summary={"We know exactly how a clean space looks like"}
        icon={OrangeMark}
        text_1={"Request our professional home cleaning service"}
        text_2={"Finetune your preference"}
        text_3={"Get your home cleaned according to your taste"}
        img={Clothe}/>
        <div className='newBtn'>
        <CommonButton/>
        </div>

      <ServiceComponent
        heading={"Food (Coming Soon)"}
        summary={
          "Let's make a delicious meal delivered to you with the best chef for you and your family on schedule"
        }
        icon={BlueMark}
        text_1={"Choose from 100+ meals"}
        text_2={"We'll deliver to you on schedule"}
        text_3={"You will enjoy your freshly cooked meals on schedule"}
        img={Food}/>
      <div className='newBtn'>

        {/* <CommonButton/> */}

       <OrangeBtn/>
        </div>
</div>
  );
}
